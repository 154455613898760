import { translations } from "../../../i18n/hr";
import * as moment from 'moment';
import { constants } from "../../../constants";
import { Calendar } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
var ReservationTable = /** @class */ (function () {
    function ReservationTable() {
        this.calendarAgenda = $('.calendar-agenda');
        this.calendarEvents = [];
        this.view_type = "week";
        this.calendar_event_color = {
            'recording': "#f5aa26",
            'meeting': "magenta",
            'rest': "maroon",
            'workshop': "#4938d7",
            'education': "#8fdf82",
            'conference': "#0e6251",
        };
        this.calendar_event_button_color = {
            'recording': "#000",
            'meeting': "#fff",
            'rest': "#fff",
            'workshop': "#fff",
            'education': "#fff",
            'conference': "#fff",
        };
        this.addListeners();
        this.generateCalendar();
    }
    ReservationTable.prototype.generateCalendar = function () {
        var _this = this;
        var calendarEl = document.getElementById('calendar');
        this.calendar = new Calendar(calendarEl, {
            plugins: [dayGridPlugin, interactionPlugin],
            dateClick: function (info) {
                var url = window.url_add_reservation;
                url = url.replace("--roomId--", $(".room.selected").data("roomid"));
                url = url.replace("--startDate--", encodeURIComponent(info.dateStr));
                url = url + "?hash=" + encodeURIComponent(location.hash);
                location.href = url;
            },
            eventClick: function (info) {
                _this.openCalendarEventOnClick(info);
            },
            locale: 'hr',
            showNonCurrentDates: false
        });
        this.calendar.render();
        this.showWeekView();
    };
    /**
     * Add event listeners
     */
    ReservationTable.prototype.addListeners = function () {
        var _this = this;
        $(document).on('click', '.reservation-element', function (e) {
            _this.toggleReservationHover(e);
        });
        $(document).on('click', '.monthView', function (e) {
            _this.showMonthView();
            $(".weekView").removeClass("active");
            $(e.currentTarget).addClass("active");
            _this.view_type = "month";
        });
        $(document).on('click', '.weekView', function (e) {
            _this.showWeekView();
            $(".monthView").removeClass("active");
            $(e.currentTarget).addClass("active");
            _this.view_type = "week";
        });
    };
    /**
     * Generate table based on data provided
     */
    ReservationTable.prototype.generateTable = function () {
        var _this = this;
        var days = this.getDaysInWeek(this.data.date);
        this.days = days;
        this.calendarAgenda.html("");
        var table = this.createTableElement();
        var headerRow = this.createRowElement();
        headerRow.append(this.createEmptyColumn());
        days.forEach(function (date, index) {
            var td = _this.createHeaderColumn(date, index);
            headerRow.append(td);
        });
        table.append(headerRow);
        this.calendarAgenda.append(table);
        for (var i = 7; i <= 23; ++i) {
            var row = this.createRowElement();
            row.append(this.createHourCoulumn(i));
            for (var j = 0; j < 7; ++j) {
                var column = this.createEmptyColumn("add-reservation-column");
                column.attr('data-hour', i);
                column.attr('data-date', this.days[j].format('YYYY-MM-DD'));
                row.append(column);
            }
            table.append(row);
        }
        this.updateDatesHtml(days);
    };
    /**
     * Create hour column and fill it with the hour text
     *
     * @param hour
     */
    ReservationTable.prototype.createHourCoulumn = function (hour) {
        var column = $('<td/>', {
            class: 'sticky-hour'
        });
        var txt = hour;
        txt = txt + ":00";
        var text = $('<p/>', {
            class: 'hour-text'
        });
        text.html(txt);
        column.append(text);
        return column;
    };
    /**
     * Create table element
     */
    ReservationTable.prototype.createTableElement = function () {
        return $('<table/>', {
            class: 'agenda-table'
        });
    };
    /**
     * Create row element
     */
    ReservationTable.prototype.createRowElement = function () {
        return $('<tr/>', {});
    };
    /**
     * Create empty column
     */
    ReservationTable.prototype.createEmptyColumn = function (cls) {
        if (cls === void 0) { cls = ""; }
        return $('<td/>', {
            class: cls
        });
    };
    /**
     * Create header column for table (dates)
     *
     * @param date
     * @param dayOfTheWeek
     */
    ReservationTable.prototype.createHeaderColumn = function (date, dayOfTheWeek) {
        var column = $('<td/>', {});
        var title = $('<p/>', {
            class: 'header-day-name'
        });
        var dateText = $('<p/>', {
            class: 'header-day-text'
        });
        title.html(this.getDay(dayOfTheWeek));
        dateText.html(date.format(translations.dateFormat));
        column.append([title, dateText]);
        return column;
    };
    /**
     * Generate calendar reservation elements
     */
    ReservationTable.prototype.generateCalendarReservationElements = function () {
        var _this = this;
        this.calendarEvents = [];
        this.calendarReservations.forEach(function (reservation) {
            var new_event = {};
            new_event['id'] = reservation.id;
            new_event['title'] = reservation.title;
            new_event['start'] = reservation.to.split(" ")[0];
            new_event['end'] = reservation.to.split(" ")[0];
            new_event['color'] = _this.calendar_event_color[reservation.event_type.name];
            new_event['textColor'] = _this.calendar_event_button_color[reservation.event_type.name];
            var new_private_event = {};
            new_private_event['id'] = reservation.id;
            new_private_event['title'] = "Zauzeto";
            new_private_event['start'] = reservation.to.split(" ")[0];
            new_private_event['end'] = reservation.to.split(" ")[0];
            new_private_event['color'] = "#D2D2D2";
            new_private_event['textColor'] = "#000";
            if (reservation && reservation.event_visibility === 'public' || window.utype === 1) {
                _this.calendarEvents.push(new_event);
            }
            else if (reservation && reservation.user_id === window.userId && window.userId !== null) {
                _this.calendarEvents.push(new_event);
            }
            else if (reservation && reservation.event_visibility === 'private') {
                _this.calendarEvents.push(new_private_event);
            }
            else if (reservation && reservation.event_visibility === 'for_tenants' && window.utype === 2) {
                _this.calendarEvents.push(new_event);
            }
            else if (reservation && reservation.event_visibility === 'for_tenants') {
                _this.calendarEvents.push(new_private_event);
            }
            else {
                _this.calendarEvents.push(new_private_event);
            }
        });
        var evtSrc = this.calendar.getEventSources();
        evtSrc.forEach(function (value) {
            value.remove();
        });
        if (this.calendarEvents) {
            this.calendar.addEventSource(this.calendarEvents);
        }
    };
    /**
     * Generate reservation elements
     */
    ReservationTable.prototype.generateReservationElements = function () {
        var _this = this;
        $(".reservation-element").remove();
        $(".add-reservation-column").removeClass('full');
        this.reservations.forEach(function (reservation) {
            var from = reservation.getFromTime();
            var date = reservation.getDateAsString();
            var rowHeight = $('.agenda-table tr').eq(2).height();
            var elementToAppend = $('td[data-hour=' + from.hour + '][data-date=' + date + ']');
            var elementHeight = rowHeight * reservation.getDurationAsNumericalUnit();
            // Top offset proportional to row height and minutes
            var topOffset = (from.minutes * rowHeight) / 60;
            var reservationElement = $('<div/>', {
                class: 'reservation-element ' + reservation.event_visibility,
            }).css({
                height: elementHeight + "px",
                top: topOffset + "px"
            }).data('reservationid', reservation.id);
            if (reservation.event_visibility === 'public' || window.utype === 1) {
                reservationElement.append(_this.generateReservationTextElement(reservation));
                reservationElement.append(_this.generateReservationHoverElement(reservation));
                elementToAppend.append(reservationElement);
                elementToAppend.addClass("full");
            }
            else if (reservation.event_visibility === 'private') {
                reservationElement.append(_this.generateReservationPrivateTextElement());
                elementToAppend.append(reservationElement);
                elementToAppend.addClass("full");
            }
            else if (reservation.event_visibility === 'for_tenants' && window.utype === 2) {
                reservationElement.append(_this.generateReservationTextElement(reservation));
                reservationElement.append(_this.generateReservationHoverElement(reservation));
                elementToAppend.append(reservationElement);
                elementToAppend.addClass("full");
            }
            else if (reservation.event_visibility === 'for_tenants') {
                reservationElement.append(_this.generateReservationPrivateTextElement());
                elementToAppend.append(reservationElement);
                elementToAppend.addClass("full");
            }
        });
    };
    /**
     * Generate reservation element when someone hovers over it
     *
     * @param reservation
     */
    ReservationTable.prototype.generateReservationHoverElement = function (reservation) {
        var reservationElement = $('<div/>', {
            class: 'reservation-hover',
        });
        var a = $('<a/>', {
            href: window.url_single_reservation.replace('--reservation--', reservation.id),
            class: 'reservation-link'
        });
        var date = $('<p/>', {
            class: 'date small'
        }).text(this.getDayForReservations(reservation.getFrom().day()) + ", " + reservation.getFrom().format('DD. MM. YYYY.'));
        var title = $('<h4/>', {
            class: 'title'
        }).text(reservation.title);
        a.append(date);
        a.append(title);
        reservationElement.append(a);
        return reservationElement;
    };
    /**
     * Genereate reservation text with the dot
     *
     * @param reservation
     */
    ReservationTable.prototype.generateReservationTextElement = function (reservation) {
        var reservationElement = $('<div/>', {
            class: 'reservation-text',
        });
        var dot;
        if (!reservation.event_type) {
            dot = $('<div/>', {
                class: 'dot ',
            });
        }
        else {
            dot = $('<div/>', {
                class: 'dot ' + reservation.event_type.name,
            });
        }
        var text = $('<p/>', {
            class: 'liner-' + Math.trunc(reservation.getDurationAsNumericalUnit())
        }).text(reservation.title);
        reservationElement.append(dot);
        reservationElement.append(text);
        return reservationElement;
    };
    /**
     * Genereate Private taken reservation
     */
    ReservationTable.prototype.generateReservationPrivateTextElement = function () {
        var reservationElement = $('<div/>', {
            class: 'reservation-text',
        });
        var text = $('<p/>', {}).text(translations.taken);
        reservationElement.append(text);
        return reservationElement;
    };
    /**
     * Get days name from day of the week
     * @param dayOfTheWeek
     */
    ReservationTable.prototype.getDay = function (dayOfTheWeek) {
        return constants.getDay(dayOfTheWeek);
    };
    /**
     * Get days name from day of the week for reservations view
     * @param dayOfTheWeek
     */
    ReservationTable.prototype.getDayForReservations = function (dayOfTheWeek) {
        return constants.getDayForReservations(dayOfTheWeek);
    };
    /**
     * Update table with reservation details
     * and new date range
     *
     * @param data
     */
    ReservationTable.prototype.updateTable = function (data) {
        this.data = data;
        this.generateTable();
    };
    /**
     * Update table with reservations
     *
     * @param reservations
     */
    ReservationTable.prototype.updateReservations = function (reservations) {
        this.reservations = reservations;
        this.generateReservationElements();
    };
    /**
     * Update calendar with reservations
     *
     * @param reservations
     */
    ReservationTable.prototype.updateCalendarReservations = function (reservations) {
        this.calendarReservations = reservations;
        this.generateCalendarReservationElements();
    };
    /**
     * Update dates text on frontend
     *
     * @param data
     */
    ReservationTable.prototype.updateDatesHtml = function (data) {
        var firstDate = data[0];
        var lastDate = data[data.length - 1];
        $('.date-from').text(firstDate.format(translations.dateFormat));
        $('.date-to').text(lastDate.format(translations.dateFormat));
    };
    /**
     * Get days in the week based on the date
     * If @param date is not set it will return
     * the week from current date
     *
     * @param date
     */
    ReservationTable.prototype.getDaysInWeek = function (date) {
        if (date === void 0) { date = null; }
        var dates = [];
        if (!date) {
            date = new Date();
        }
        var lastday = new Date(date.setDate((date.getDate() - (date.getDay() - 1) + 6)));
        for (var i = 1; i < 7; ++i) {
            var olderDate = lastday.getTime() - ((7 - i) * (24 * 60 * 60 * 1000));
            dates.push(moment(new Date(olderDate)));
        }
        dates.push(moment(lastday));
        return dates;
    };
    /**
     * Get date from calculated days earlier
     * 0 - 6 indicating days of the week.
     *
     * @param index
     */
    ReservationTable.prototype.getDayDateByIndex = function (index) {
        return this.days[index];
    };
    /**
     * Toggle reservation hover element
     * Reservations hover elements hold additional info
     * about taken event
     *
     * @param e
     */
    ReservationTable.prototype.toggleReservationHover = function (e) {
        var resid = ($(e.target).data('reservationid'));
        var reservation = this.getReservationById(resid);
        if (window.utype === 1) {
            location.href = window.url_single_reservation.replace('--reservation--', resid);
        }
        if (reservation.event_visibility === 'public' || (reservation.event_visibility === 'for_tenants' && window.utype === 2)) {
            location.href = window.url_single_reservation.replace('--reservation--', resid);
        }
        e.preventDefault();
        e.stopPropagation();
    };
    /**
     * Register click event on calendar event
     *
     * @param e
     */
    ReservationTable.prototype.openCalendarEventOnClick = function (e) {
        if (e) {
            var resid = e.event.id;
            var reservation = this.getReservationById(resid);
            if (window.utype === 1) {
                location.href = window.url_single_reservation.replace('--reservation--', resid);
            }
            if (reservation.event_visibility === 'public' || (reservation.event_visibility === 'for_tenants' && window.utype === 2)) {
                location.href = window.url_single_reservation.replace('--reservation--', resid);
            }
            if ((window.userId === reservation.user_id) && reservation.user_id !== null) {
                location.href = window.url_single_reservation.replace('--reservation--', resid);
            }
            //e.preventDefault();
            //e.stopPropagation();
        }
    };
    /**
     * Get reservation for the room and specified dates
     */
    ReservationTable.prototype.getReservations = function () {
        return this.reservations;
    };
    /**
     * Get reservation by id
     *
     * @param id
     */
    ReservationTable.prototype.getReservationById = function (id) {
        var res = this.reservations.find(function (reservation) {
            return reservation.id == id;
        });
        if (res) {
            return res;
        }
        return this.calendarReservations.find(function (reservation) {
            return reservation.id == id;
        });
    };
    /**
     * Toggle reservation calendar month view
     */
    ReservationTable.prototype.showMonthView = function () {
        $('#calendar').fadeIn(500);
        $('#weekView').hide();
    };
    /**
     * Toggle reservation calendar week view
     */
    ReservationTable.prototype.showWeekView = function () {
        $('#weekView').fadeIn(500);
        $('#calendar').hide();
    };
    return ReservationTable;
}());
export { ReservationTable };
