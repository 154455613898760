import { constants } from "../../../constants";
import { Helper } from "../../helpers/Helper";
var Tenant = /** @class */ (function () {
    function Tenant() {
        if (window.template === constants.template_tenant) {
            this.addListeners();
        }
    }
    /**
     * Add listeners
     */
    Tenant.prototype.addListeners = function () {
        var _this = this;
        $(document).on('click', '.add_module', function (e) {
            _this.add_module(e);
        });
        $(document).on('click', '.module-delete-modal', function (e) {
            _this.deleteModule(e);
        });
        $(document).on('click', '.add-branch', function (e) {
            console.log("add-branch clicked");
            _this.add_branch_office(e);
        });
        $(document).on('click', '.remove-branch', function (e) {
            _this.remove_branch_office(e);
        });
        $(document).on('click', '#add-company-member', function (e) {
            _this.addEmployee(e);
        });
        $(document).on('click', '.deleteEmployee', function (e) {
            _this.removeEmployee(e);
        });
        $(document).on('submit', '.userChangeFormModal form', function (e) {
            var shouldSubmit = _this.validate_form(e);
            if (!shouldSubmit) {
                e.preventDefault();
                return false;
            }
            return true;
        });
        jQuery(".modal").on('hide.bs.modal', function (e) {
            $(e.currentTarget).find('.invalid-feedback').hide();
        });
        $(document).on('click', '.save-un-requested-data', function (e) {
            _this.saveUnRequestedChanges(e);
        });
        $(document).on('click', '#add-student', function (e) {
            _this.addStudent(e);
        });
        // Listener for removing students
        $(document).on('click', '.deleteStudent', function (e) {
            _this.removeStudent(e);
        });
    };
    /**
     *  Remove employee form from view
     *
     * @param e
     */
    Tenant.prototype.removeEmployee = function (e) {
        var element = e.currentTarget;
        var containterElement = $(element).closest('.employee-form');
        var totalEmployeeForms = $('.employee-form').length;
        // If deleting last form, reset and hide it
        if (totalEmployeeForms === 1) {
            var lastForm = $('.employee-form');
            lastForm.find('input').val("");
            lastForm.addClass('d-none');
            // Since this is the last employee, ceo has to be the employee
            $('#check-ceo-company-emplyoee').prop('checked', true);
        }
        else {
            containterElement.remove();
        }
        this.updateEmployeeMetaBox(true);
    };
    /**
     * Add employee
     * @param e
     * @param preventDefault
     */
    Tenant.prototype.addEmployee = function (e, preventDefault) {
        if (preventDefault === void 0) { preventDefault = true; }
        if (preventDefault) {
            e.preventDefault();
        }
        var form = $(".employee-form.d-none");
        var total_children = $(".employee-form-holder").children().not('.d-none').length;
        var cloned = $(form).clone(false);
        cloned.removeClass('d-none');
        cloned.find('input').val("");
        //cloned.find('.added_document').remove();
        //cloned.find('.dropify-preview').hide();
        cloned.find('.employee_number').html(total_children + "");
        cloned.find('.invalid-feedback').remove();
        cloned.find('.dropify').prop('id', 'cv-file' + total_children);
        cloned.find('input:not([type=hidden])').each(function (index, element) {
            $(element).empty();
        });
        cloned.find('select').each(function (index, element) {
            $(element).prop('selectedIndex', 0);
        });
        cloned.find('.added_document').remove();
        cloned.prepend('<br>');
        $('.employee-form-holder').append(cloned);
        this.updateEmployeeMetaBox(false);
    };
    /**
     *  Update total number of employees
     *
     * @param isDelete
     */
    Tenant.prototype.updateEmployeeMetaBox = function (isDelete) {
        var employeeCounterElement = $('input[name="total_employees"]');
        var addEmployeeButton = $("#add-company-member");
        var employeeCount = +employeeCounterElement.val();
        employeeCount += 1;
        // Update employee counter element wih count of total employee forms
        if (isDelete) {
            employeeCount -= 2;
        }
        employeeCounterElement.val(employeeCount);
        // Change link text for adding more employees
        var buttonText = addEmployeeButton.data('pluralText');
        if (employeeCount === 1) {
            buttonText = addEmployeeButton.data('pluralText');
        }
        addEmployeeButton.html(buttonText);
        this.updateEmployeeIndexes();
    };
    Tenant.prototype.updateEmployeeIndexes = function () {
        var counter = 1;
        var employeeForms = $('.employee-form').not('.d-none');
        employeeForms.each(function (index, object) {
            $(this).find('.employee_number').html(counter + "");
            counter++;
        });
    };
    Tenant.prototype.addStudent = function (e) {
        e.preventDefault();
        var formTemplate = $(".student-form.d-none").first();
        if (formTemplate.length === 0) {
            console.error("No student form template found.");
            return;
        }
        var totalStudents = $(".student-form-holder .student-form").not('.d-none').length;
        var cloned = formTemplate.clone(true);
        cloned.removeClass('d-none');
        // Reset input values for text and telephone inputs
        cloned.find('input[type="text"], input[type="tel"]').val("");
        // Ensure date of birth selects are reset
        cloned.find('.date-picker select').each(function () {
            $(this).prop('selectedIndex', 0);
        });
        // Reset gender select to default value
        cloned.find('select[name="student_gender[]"]').prop('selectedIndex', 0);
        // Remove any existing validation messages
        cloned.find('.invalid-feedback').remove();
        // Reset and hide any invalid input indications
        cloned.find('.is-invalid').removeClass('is-invalid');
        cloned.find('.student_number').html((totalStudents + 1).toString());
        cloned.prepend('<br>');
        $(".student-form-holder").append(cloned);
        this.updateStudentMetaBox(false);
    };
    Tenant.prototype.removeStudent = function (e) {
        var element = e.currentTarget;
        var containerElement = $(element).closest('.student-form');
        var totalStudentForms = $('.student-form').not('.d-none').length;
        if (totalStudentForms === 1) {
            var lastForm = $('.student-form').not('.d-none').first();
            lastForm.find('input').val("");
            lastForm.addClass('d-none');
        }
        else {
            containerElement.remove();
        }
        this.updateStudentMetaBox(true);
    };
    Tenant.prototype.updateStudentMetaBox = function (isDelete) {
        var studentCounterElement = $('input[name="total_students"]');
        var addStudentButton = $("#add-student");
        var studentCount = +studentCounterElement.val();
        studentCount += isDelete ? -1 : 1;
        studentCounterElement.val(studentCount);
        var buttonText = studentCount === 1 ? addStudentButton.data('singularText') : addStudentButton.data('pluralText');
        addStudentButton.html(buttonText);
        this.updateStudentIndexes();
    };
    Tenant.prototype.updateStudentIndexes = function () {
        var counter = 1;
        var studentForms = $('.student-form').not('.d-none');
        studentForms.each(function () {
            $(this).find('.student_number').html(counter.toString());
            counter++;
        });
    };
    /**
     *  Handle saving of unrequested changes
     *
     *
     * @param e
     */
    Tenant.prototype.saveUnRequestedChanges = function (e) {
        var elements = $(".data-container").find('input, select, textarea').not(':disabled');
        var submitAction = $(e.currentTarget).data('href');
        var token = $(e.currentTarget).data('token');
        var form = $('<form>', {
            'id': 'newDataForm',
            'action': submitAction,
            'method': 'post',
            'enctype': 'multipart/form-data'
        }).appendTo(document.body);
        elements.each(function (nr, element) {
            if ($(element).is('select[multiple]')) {
                $(element).find('option:selected').each(function () {
                    form.append($('<input>', {
                        'name': $(element).attr('name'),
                        'value': $(this).val(),
                        'type': 'hidden'
                    }));
                });
            }
            else {
                var inModal = $(element).parents('.userChangeFormModal');
                var inEmployeeTemplate = $(element).parents('.employee-form.d-none');
                if (inModal.length > 0 || inEmployeeTemplate.length > 0 || !$(element).val()) {
                    return;
                }
                if ($(element).attr('type') === 'hidden') {
                    if (!$(element).hasClass('picker-value')) {
                        return;
                    }
                }
                if ($(element).attr('type') === 'file') {
                    var $element = $(element);
                    var $clone = $element.clone(true);
                    // Replace id on clone input so that there are no multiple elements with same id
                    $clone.attr('id', $clone.attr('id') + "newValue");
                    // Replace (append) cloned element after original
                    $element.after($clone);
                    // Move original to new form
                    form.append($element);
                    return;
                }
                form.append($('<input>', {
                    'name': $(element).attr('name'),
                    'value': $(element).val(),
                    'type': $(element).attr('type')
                }));
            }
        });
        form.append($('<input>', {
            'type': 'hidden',
            'name': '_token',
            'value': token
        }));
        // Special cases
        if ($(".data-container").find('ceo_id').length > 0) {
            form.append($('<input>', {
                'type': 'hidden',
                'name': 'ceo_id',
                'value': $(".data-container").find('ceo_id').val()
            }));
        }
        if ($(".data-container").find('employee_id').length > 0) {
            form.append($('<input>', {
                'type': 'hidden',
                'name': 'employee_id',
                'value': $(".data-container").find('employee_id').val()
            }));
        }
        form.submit();
    };
    /**
     *  Check if form needs validation and validate if so
     *
     * @param e
     * @return boolean
     */
    Tenant.prototype.validate_form = function (e) {
        var formTypeInput = $(e.currentTarget).find('input[name="form_type"]');
        if (formTypeInput.length === 0) {
            return true;
        }
        var formType = formTypeInput.val();
        if (formType === 'radio-button') {
            return this.validateRadioButtonForm(e);
        }
        if (formType === 'text') {
            return this.validateTextForm(e);
        }
        if (formType === 'textarea') {
            return this.validateTextareaForm(e);
        }
        if (formType === 'address') {
            return this.validateAddressForm(e);
        }
        if (formType === 'date-picker') {
            return this.validateDatePickerForm(e);
        }
        if (formType === 'file') {
            return this.validateFileForm(e);
        }
        if (formType === 'select') {
            return this.validateSelectForm(e);
        }
        if (formType === 'delete') {
            return true;
        }
    };
    /**
     *  Validate select form
     *
     * @param e
     */
    Tenant.prototype.validateSelectForm = function (e) {
        var disabledDatePickerFields = $(e.currentTarget).find('option[disabled]:selected');
        if (disabledDatePickerFields.length === 0) {
            return true;
        }
        $(e.currentTarget).find('.invalid-feedback').show();
        return false;
    };
    /**
     *  Validate textarea form
     *
     * @param e
     */
    Tenant.prototype.validateFileForm = function (e) {
        var fileInput = $(e.currentTarget).find('input[type="file"]').val();
        if (fileInput) {
            return true;
        }
        $(e.currentTarget).find('.invalid-feedback').show();
        return false;
    };
    /**
     *  Validate textarea form
     *
     * @param e
     */
    Tenant.prototype.validateDatePickerForm = function (e) {
        // Since there is no default value on date picker currently so this will always be empty
        var disabledDatePickerFields = $(e.currentTarget).find('option[disabled]:selected');
        if (disabledDatePickerFields.length === 0) {
            return true;
        }
        $(e.currentTarget).find('.invalid-feedback').show();
        return false;
    };
    /**
     *  Validate address form
     *
     * @param e
     */
    Tenant.prototype.validateAddressForm = function (e) {
        var addressTextFieldsValid = true;
        var emptyTextFields = $(e.currentTarget).find('input').each(function () {
            var element = $(this);
            if (element.attr('type') !== 'hidden' && element.val() === '') {
                addressTextFieldsValid = false;
            }
        });
        if (!addressTextFieldsValid) {
            $(e.currentTarget).find('.invalid-feedback').show();
            return false;
        }
        // Since there is currently only one select field and there is only one options, this can be skipped
        //let selectField = $(e.currentTarget).find('select:selected').val();
        //if (selectField !== '') {
        //    $(e.currentTarget).find('.invalid-feedback').show();
        //    return falseM
        //}
        return true;
    };
    /**
     *  Validate textarea form
     *
     * @param e
     */
    Tenant.prototype.validateTextareaForm = function (e) {
        var fieldValue = $(e.currentTarget).find('textarea').val();
        if (fieldValue) {
            return true;
        }
        $(e.currentTarget).find('.invalid-feedback').show();
        return false;
    };
    /**
     *  Validate text form
     *
     * @param e
     */
    Tenant.prototype.validateTextForm = function (e) {
        var fieldValue = $(e.currentTarget).find('input[type="text"], input[type="number"], input[type="email"]').val();
        if (fieldValue) {
            return true;
        }
        $(e.currentTarget).find('.invalid-feedback').show();
        return false;
    };
    /**
     *  Validate radio button form
     *
     * @param e
     */
    Tenant.prototype.validateRadioButtonForm = function (e) {
        var isValid = $(e.currentTarget).find('input[type="radio"]:checked').length;
        if (isValid > 0) {
            return true;
        }
        $(e.currentTarget).find('.invalid-feedback').show();
        return false;
    };
    /**
     *  Delete user module
     *
     * @param e
     */
    Tenant.prototype.deleteModule = function (e) {
        var buttonElement = $(e.currentTarget);
        var _a = buttonElement.data(), moduleId = _a.moduleId, route = _a.route, token = _a.token, groupId = _a.groupId;
        if (!moduleId || !route || !token || !groupId) {
            if (groupId) {
                $('#deleteModuleModal' + groupId).find('.close').click();
                $('#moduleGroup' + groupId).remove();
            }
            $('#deleteModuleModal' + groupId).find('.close').click();
            $('.modal-backdrop.fade').remove();
            $('#moduleGroup' + groupId).remove();
            return;
        }
        Helper.sendAjaxRequest('POST', route, {
            module_id: moduleId,
            _token: token
        });
        $('#deleteModuleModal' + groupId).find('.close').click();
        $('.modal-backdrop.fade').remove();
        $('#moduleGroup' + groupId).remove();
    };
    /**
     * Add user module
     * @param e
     */
    Tenant.prototype.add_module = function (e) {
        e.preventDefault();
        var form = $(".module-group")[0];
        var cloned = $(form).clone(false);
        var clonedElementId = $(".module-group").not('d-none').length + 1;
        cloned.removeClass('d-none');
        cloned.find('select').attr('name', 'module[]');
        cloned.attr('id', 'moduleGroup' + clonedElementId);
        cloned.find('.module-delete').attr('data-target', '#deleteModuleModal' + clonedElementId);
        var deleteModal = cloned.find('.modal');
        if (deleteModal.length > 0) {
            deleteModal.attr('id', 'deleteModuleModal' + clonedElementId);
            deleteModal.find('.module-delete-modal').removeAttr('data-module-id');
            deleteModal.find('.module-delete-modal').attr('data-group-id', clonedElementId);
        }
        else {
            console.log("nema toga");
        }
        cloned.find('.title').remove();
        cloned.find('br').remove();
        $('.module-holder').append(cloned);
    };
    /**
     * Add branch office
     * @param e
     */
    Tenant.prototype.add_branch_office = function (e) {
        try {
            e.preventDefault();
            var cloned = void 0;
            if ($(".branch-group").not('.d-none').length > 0) {
                var lastBranch = $(".branch-group").not('.d-none').last();
                cloned = lastBranch.clone(false);
                cloned.find('input').val("");
                cloned.find('select').val("");
            }
            else {
                var template = $(".branch-group.d-none").first();
                cloned = template.clone(false);
                cloned.removeClass('d-none');
                cloned.find('input').val("");
                cloned.find('select').val("");
            }
            cloned.find('.remove-branch').remove();
            var removeButton = $('<button/>', {
                class: 'remove-branch btn btn-sm btn-danger',
                html: '<i class="fe-trash"></i>',
                click: this.remove_branch_office.bind(this)
            });
            var buttonContainer = $('<div/>', {
                class: 'col-lg-12 d-flex justify-content-between align-items-center',
            });
            var h5 = cloned.find('h5').remove();
            buttonContainer.append(h5, removeButton);
            cloned.prepend(buttonContainer);
            cloned.find('input, select').prop('disabled', false);
            $('.branch-holder').append(cloned);
            var newBranchNumber = $(".branch-group").not('.d-none').length;
            $('.branch-holder').children().last().find('h5').first().text('Podružnica ' + newBranchNumber);
        }
        catch (error) {
            console.error(error);
        }
    };
    Tenant.prototype.updateBranchNumber = function () {
        $('.branch-group').not('.d-none').each(function (index, element) {
            $(element).find('.branchNumber').html((index + 1).toString());
        });
    };
    Tenant.prototype.remove_branch_office = function (e) {
        e.preventDefault();
        var target = $(e.currentTarget);
        var final_element = target.closest('.branch-group').not('.d-none');
        final_element.remove();
        this.updateBranchNumber();
    };
    return Tenant;
}());
export { Tenant };
