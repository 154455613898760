import { ReservationTable } from "./ReservationTable";
import * as moment from 'moment';
import { ReservationService } from "../../services/Reservation.service";
var Reservations = /** @class */ (function () {
    function Reservations() {
        this.weekOffset = 0;
        this.currentDate = new Date();
        this.reservationService = new ReservationService();
        if ($(".calendar-agenda").length > 0) {
            this.generateTable();
            this.registerEventListeners();
            this.parseHash();
        }
    }
    /**
     * Parse hash and load data based on hash
     */
    Reservations.prototype.parseHash = function () {
        if (location.hash.length > 0) {
            var hashComponents = location.hash.split(',');
            try {
                if (hashComponents.length === 1) {
                    var room_id = hashComponents[0].split('=')[1];
                    this.onRoomIdSelect(room_id);
                }
                else {
                    var day = hashComponents[0].split('=')[1];
                    var room_id = hashComponents[1].split('=')[1];
                    var dayComponents = day.split("-");
                    this.currentDate = new Date(parseInt(dayComponents[0]), parseInt(dayComponents[1]) - 1, parseInt(dayComponents[2]));
                    this.reservationTable.updateTable({
                        date: this.getCurrentDate()
                    });
                    this.onRoomIdSelect(room_id);
                }
            }
            catch (e) {
            }
        }
        else {
            this.setRoomByIndex(0);
        }
    };
    /**
     * Set hash based on day and room id
     *
     * @param day
     * @param room_id
     */
    Reservations.prototype.setHash = function (day, room_id) {
        location.hash = 'day=' + day + ",room_id=" + room_id;
    };
    /**
     * Register event listeners on dom elements
     */
    Reservations.prototype.registerEventListeners = function () {
        var _this = this;
        $(".room").on('click', function (e) {
            _this.onRoomSelect(e);
        });
        $(".prev-week").on('click', function () {
            _this.prevWeek();
        });
        $(".next-week").on('click', function () {
            _this.nextWeek();
        });
        $(".fc-prev-button").on('click', function () {
            _this.prevMonth();
        });
        $(".fc-next-button").on('click', function () {
            _this.nextMonth();
        });
        $(".fc-today-button").on('click', function () {
            _this.thisMonth();
        });
        $(document).on('click', '.add-reservation-column', function (e) {
            _this.addReservation(e);
        });
    };
    /**
     * Call main class responsible for generating
     * reservation table
     */
    Reservations.prototype.generateTable = function () {
        this.reservationTable = new ReservationTable();
        var clonedDate = this.getCurrentDate();
        this.reservationTable.updateTable({
            date: clonedDate
        });
    };
    /**
     * Create clone of current date and return it
     */
    Reservations.prototype.getCurrentDate = function () {
        return new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate());
    };
    /**
     * On room select callback
     * If element has child rooms toggle collapsed class
     * to show or hide child rooms
     */
    Reservations.prototype.onRoomSelect = function (event) {
        if ($(event.currentTarget).find('ul').length > 0) {
            $(event.currentTarget).toggleClass('collapsed');
        }
        else {
            event.stopPropagation();
            var title = $(event.currentTarget).text();
            var roomId = $(event.currentTarget).data('roomid');
            this.setRoomById(roomId, title);
        }
    };
    Reservations.prototype.onRoomIdSelect = function (room_id) {
        var title = $('.room[data-roomid=' + room_id + ']').data('title');
        this.setRoomById(parseInt(room_id), title);
    };
    /**
     * Decrease date for one week
     */
    Reservations.prototype.prevWeek = function () {
        this.weekOffset = this.weekOffset - 1;
        this.setWeekFromOffset(this.weekOffset);
    };
    /**
     * Increase date for one week
     */
    Reservations.prototype.nextWeek = function () {
        this.weekOffset = this.weekOffset + 1;
        this.setWeekFromOffset(this.weekOffset);
    };
    /**
     * Decrease date for one month
     */
    Reservations.prototype.prevMonth = function () {
        var date = new Date($(".fc-day:not(.fc-disabled-day)").eq(0).attr("data-date"));
        var clonedDate = new Date(date.getTime());
        this.loadMonthReservations(clonedDate);
    };
    /**
     * Increase date for one month
     */
    Reservations.prototype.nextMonth = function () {
        var date = new Date($(".fc-day:not(.fc-disabled-day)").eq(0).attr("data-date"));
        var clonedDate = new Date(date.getTime());
        this.loadMonthReservations(clonedDate);
    };
    /**
     * Set current month
     */
    Reservations.prototype.thisMonth = function () {
        var date = new Date();
        var clonedDate = new Date(date.getTime());
        this.loadMonthReservations(clonedDate);
    };
    /**
     * Calculate the date offset from current date based
     * on given offset and update calendar
     *
     * @param offset
     */
    Reservations.prototype.setWeekFromOffset = function (offset) {
        var newDate = new Date(this.getCurrentDate().getTime() + (this.weekOffset * Reservations.WEEK_MILLS));
        var clonedDate = new Date(newDate.getTime());
        this.reservationTable.updateTable({
            date: newDate
        });
        this.loadWeekReservations(clonedDate);
    };
    /**
     * Load week reservations based on the date
     * It will first get the last day and first day of the week for
     * given date and this will be the range for reservations query
     *
     * @param date
     */
    Reservations.prototype.loadWeekReservations = function (date) {
        var _this = this;
        this.setHash(moment(date).format('YYYY-MM-DD'), this.selectedRoom.id);
        var days = this.getFirstAndLastDayInWeek(date);
        this.showLoader();
        this.reservationService.getReservations(days.fromDate, days.toDate, this.selectedRoom.id)
            .then(function (reservations) {
            _this.reservationTable.updateReservations(reservations);
            _this.hideLoader();
        });
    };
    /**
     * Load month reservations based on the date
     * It will first get the last day and first day of the month for
     * given date and this will be the range for reservations query
     *
     * @param date
     */
    Reservations.prototype.loadMonthReservations = function (date) {
        var _this = this;
        this.setHash(moment(date).format('YYYY-MM-DD'), this.selectedRoom.id);
        var days = this.getFirstAndLastDayInMonth(date);
        this.showLoader();
        this.reservationService.getReservations(days.fromDate, days.toDate, this.selectedRoom.id)
            .then(function (reservations) {
            _this.reservationTable.updateCalendarReservations(reservations);
            _this.hideLoader();
        });
    };
    /**
     * This should only be used for first level rooms
     * If room doesn't exist it will throw an error
     *
     * @param index
     */
    Reservations.prototype.setRoomByIndex = function (index) {
        var room = $(".room").eq(index);
        if (room) {
            this.selectedRoom = {
                id: room.data('roomid'),
                title: room.data('title')
            };
            this.updateRoomHtml(this.selectedRoom);
            console.log("TEST");
            this.loadWeekReservations(this.getCurrentDate());
            this.loadMonthReservations(this.getCurrentDate());
        }
        else {
            throw "Room with index " + index + " doesn't exist";
        }
    };
    /**
     * Set room by its id
     *
     * Title param is optional. If title is null
     * the algorithm will first find title by id in dom
     *
     * @param roomId
     * @param title
     */
    Reservations.prototype.setRoomById = function (roomId, title) {
        if (!title) {
            title = this.getRoomTitle(roomId);
        }
        this.selectedRoom = {
            id: roomId,
            title: title
        };
        this.updateRoomHtml(this.selectedRoom);
        this.loadWeekReservations(this.getCurrentDate());
        this.loadMonthReservations(this.getCurrentDate());
    };
    /**
     * Return room title base on room id
     *
     * @param roomId
     */
    Reservations.prototype.getRoomTitle = function (roomId) {
        return $(".room[data-roomid='" + roomId + "']").data('title');
    };
    /**
     * Update room title text
     *
     * @param data
     */
    Reservations.prototype.updateRoomHtml = function (data) {
        $('.selected-room').text(data.title);
        $(".room").removeClass('selected');
        $(".room[data-roomid='" + data.id + "']").addClass('selected');
    };
    /**
     * Create request for new reservation
     *
     * @param event
     */
    Reservations.prototype.addReservation = function (event) {
        // Check if user is logged in
        // if(!$("#auth_user_id").html()) {
        //     return;
        // }
        var url = window.url_add_reservation;
        var selectedDayIndex = $(event.currentTarget).index() - 1;
        var selectedHourIndex = $(event.currentTarget).parent("tr").index();
        var date = this.reservationTable.getDayDateByIndex(selectedDayIndex).clone();
        date.startOf('day')
            .add(7, 'hours')
            .add((selectedHourIndex - 1) * 60, 'minutes');
        if (this.checkIfReservationOverlaps(selectedDayIndex + 1, selectedHourIndex)) {
            date.add(30, 'minutes');
        }
        url = url.replace("--roomId--", this.selectedRoom.id);
        url = url.replace("--startDate--", encodeURIComponent(date.format('YYYY-MM-DD HH:mm:ss')));
        url = url + "?hash=" + encodeURIComponent(location.hash);
        location.href = url;
    };
    /**
     * Check if selected column overlaps with some of the reservations
     * if so return true
     */
    Reservations.prototype.checkIfReservationOverlaps = function (day, hour) {
        var reservations = this.reservationTable.getReservations();
        for (var i = 0; i < reservations.length; ++i) {
            var reservation = reservations[i];
            if (reservation.getFrom().day() === day) {
                if (reservation.getTo().hour() === hour) {
                    return true;
                }
            }
        }
        return false;
    };
    /**
     * Get first and last days in week
     *
     * @param date
     */
    Reservations.prototype.getFirstAndLastDayInWeek = function (date) {
        if (date === void 0) { date = null; }
        if (!date) {
            date = new Date();
        }
        var lastDay = new Date(date.setDate((date.getDate() - (date.getDay() - 1) + 6)));
        var firstDay = new Date(lastDay.getTime() - (6 * (24 * 60 * 60 * 1000)));
        return {
            fromDate: moment(firstDay),
            toDate: moment(lastDay)
        };
    };
    /**
     * Get first and last day of month
     *
     * @param date
     */
    Reservations.prototype.getFirstAndLastDayInMonth = function (date) {
        if (date === void 0) { date = null; }
        if (!date) {
            date = new Date();
        }
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 7);
        return {
            fromDate: moment(firstDay),
            toDate: moment(lastDay)
        };
    };
    /**
     * Show loader
     */
    Reservations.prototype.showLoader = function () {
        $(".calendar-loader").css('display', 'block');
    };
    /**
     * Hide loader
     */
    Reservations.prototype.hideLoader = function () {
        $(".calendar-loader").css('display', 'none');
    };
    Reservations.WEEK_MILLS = 604800000;
    return Reservations;
}());
export { Reservations };
