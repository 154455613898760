import { ReservationModel } from "../models/ReservationModel";
var ReservationFactory = /** @class */ (function () {
    function ReservationFactory() {
    }
    /**
     * Create reservation model based on the date received
     *
     * @param reservationData
     */
    ReservationFactory.createReservation = function (reservationData) {
        var reservation = new ReservationModel();
        Object.assign(reservation, reservationData);
        reservation.init();
        return reservation;
    };
    return ReservationFactory;
}());
export { ReservationFactory };
