import { Helper } from "../helpers/Helper";
var ReservationModel = /** @class */ (function () {
    function ReservationModel() {
    }
    ReservationModel.prototype.init = function () {
    };
    /**
     * Get from time hours and minutes
     */
    ReservationModel.prototype.getFromTime = function () {
        return {
            hour: this.getFrom().get('hour'),
            minutes: this.getFrom().get('minutes')
        };
    };
    /**
     * Get to time hours and minutes
     */
    ReservationModel.prototype.getToTime = function () {
        return {
            hour: this.getTo().get('hour'),
            minutes: this.getTo().get('minutes')
        };
    };
    /**
     * Get from date as momemt date
     */
    ReservationModel.prototype.getFrom = function () {
        if (this.from_date) {
            return this.from_date;
        }
        this.from_date = Helper.parseDefaultDate(this.from);
        return this.from_date;
    };
    /**
     * Get to date as momemt date
     */
    ReservationModel.prototype.getTo = function () {
        if (this.to_date) {
            return this.to_date;
        }
        this.to_date = Helper.parseDefaultDate(this.to);
        return this.to_date;
    };
    /**
     * Get just date as string
     */
    ReservationModel.prototype.getDateAsString = function () {
        return this.getFrom().format("YYYY-MM-DD");
    };
    /**
     * Get duration as numerical unit
     * Minutes will be converted to decimal places of a number
     * and they will be added to the hour. So 1:30 hours will be 1.5
     */
    ReservationModel.prototype.getDurationAsNumericalUnit = function () {
        var fromTime = Helper.convertTimeToNumericUnit(this.getFromTime());
        var toTime = Helper.convertTimeToNumericUnit(this.getToTime());
        return toTime - fromTime;
    };
    return ReservationModel;
}());
export { ReservationModel };
