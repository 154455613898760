var Textarea = /** @class */ (function () {
    function Textarea() {
        this.addListeners();
        this.setupMaxFields();
    }
    Textarea.prototype.addListeners = function () {
        var _this = this;
        $(document).on('input', 'textarea[data-max]', function (e) {
            _this.setMaxField(e);
        });
    };
    Textarea.prototype.setupMaxFields = function () {
        var _this = this;
        $('textarea[data-max]').each(function (index, element) {
            _this.setMaxField({ currentTarget: element });
        });
    };
    Textarea.prototype.setMaxField = function (e) {
        $(e.currentTarget).parent().find('.total-typed').text($(e.currentTarget).val().length);
    };
    return Textarea;
}());
export { Textarea };
