var ReservationCheckin = /** @class */ (function () {
    function ReservationCheckin() {
        if (window.subtemplate === 'reservation-checkins') {
            this.addListeners();
        }
    }
    ReservationCheckin.prototype.addListeners = function () {
        $(".add-more-names").on('click', this.addMoreNames);
        $(document).on('click', '.remove-name', this.removeName);
    };
    ReservationCheckin.prototype.addMoreNames = function (e) {
        e.preventDefault();
        var cloned = $(".one-name").eq(0).clone();
        cloned.find('input').val('');
        cloned.appendTo($('.names-holder'));
    };
    ReservationCheckin.prototype.removeName = function (e) {
        e.preventDefault();
        $(e.currentTarget).closest('.one-name').remove();
    };
    return ReservationCheckin;
}());
export { ReservationCheckin };
