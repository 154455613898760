var WarningPopup = /** @class */ (function () {
    function WarningPopup() {
        this.hideAllAlerts();
        this.appendClose();
        this.addListeners();
        this.showCookieWarnings();
    }
    WarningPopup.prototype.appendClose = function () {
        $(".alert").each(function (index, element) {
            if (!$(element).hasClass("permanent")) {
                $(element).append("<span class='close'>X</span>");
            }
        });
    };
    WarningPopup.prototype.hideAllAlerts = function () {
        setTimeout(function () {
            $(".alert").each(function (index, element) {
                if (!$(element).hasClass("permanent")) {
                    $(element).remove();
                }
            });
        }, 15000);
    };
    /**
     * Add listener
     */
    WarningPopup.prototype.addListeners = function () {
        $(document).on('click', '.warning-zic .close', this.closeWarning);
        $(document).on('click', '.alert .close', this.closeFlashMessage);
    };
    WarningPopup.prototype.closeFlashMessage = function () {
        $(this).parent().remove();
    };
    /**
     * Show hidden warnings that are not closed already
     */
    WarningPopup.prototype.showCookieWarnings = function () {
        var _this = this;
        $('.warning-zic').each(function (index, element) {
            if ($(element).data('cname')) {
                var cookie = _this.getCookie($(element).data('cname'));
                if (!cookie) {
                    $(element).css('display', 'block');
                }
            }
        });
    };
    /**
     * Close warning and save cookie
     * @param e
     */
    WarningPopup.prototype.closeWarning = function (e) {
        var warning_element = $(e.currentTarget).closest('.warning-zic');
        if (warning_element.data('cname')) {
            document.cookie = warning_element.data('cname') + "=true; expires=Thu, 18 Dec " + (new Date().getFullYear() + 2) + " 12:00:00 UTC";
        }
        $(e.currentTarget).closest('.warning-zic').remove();
    };
    /**
     * Get cookie by name
     * @param cname
     */
    WarningPopup.prototype.getCookie = function (cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return false;
    };
    return WarningPopup;
}());
export { WarningPopup };
