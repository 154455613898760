import { translations } from "./i18n/hr";
export var constants = {
    template_add_room: 'add_room',
    template_admin_edit_request_reservation: 'admin_edit_request_reservation',
    template_registration: 'registration',
    template_tenant: 'tenant',
    endpoint_load_reservations: '/v1/reservations/{start_date}/end/{end_date}/room/{room_id}',
    endpoint_reservation_times: '/v1/reservation/times',
    get_api_endpoint: function () {
        return location.origin + "/web-api";
    },
    get_root_api_endpoint: function () {
        return location.origin + "/api";
    },
    success: 'success',
    getDay: function (dayOfTheWeek) {
        if (dayOfTheWeek === 0) {
            return translations.mon;
        }
        if (dayOfTheWeek === 1) {
            return translations.tue;
        }
        if (dayOfTheWeek === 2) {
            return translations.wed;
        }
        if (dayOfTheWeek === 3) {
            return translations.thu;
        }
        if (dayOfTheWeek === 4) {
            return translations.fri;
        }
        if (dayOfTheWeek === 5) {
            return translations.sat;
        }
        if (dayOfTheWeek === 6) {
            return translations.sun;
        }
    },
    getDayForReservations: function (dayOfTheWeek) {
        if (dayOfTheWeek === 1) {
            return translations.mon;
        }
        if (dayOfTheWeek === 2) {
            return translations.tue;
        }
        if (dayOfTheWeek === 3) {
            return translations.wed;
        }
        if (dayOfTheWeek === 4) {
            return translations.thu;
        }
        if (dayOfTheWeek === 5) {
            return translations.fri;
        }
        if (dayOfTheWeek === 6) {
            return translations.sat;
        }
        if (dayOfTheWeek === 0) {
            return translations.sun;
        }
    }
};
