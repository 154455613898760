import { AddRoom } from "./classes/resources/reservations/AddRoom";
import { Reservations } from "./classes/resources/reservations/Reservations";
import { Textarea } from "./classes/forms/Textarea";
import { UserRegistration } from "./classes/resources/user-registration/UserRegistration";
import { DatePicker } from "./classes/widgets/DatePicker";
import { UserRequest } from "./classes/resources/user-request/UserRequest";
import { ReportedProblem } from "./classes/resources/reported-problem/ReportedProblem";
import { Tenant } from "./classes/resources/tenants/Tenant";
import { ReservationCheckin } from "./classes/resources/reservation-checkins/ReservationCheckin";
import { WarningPopup } from "./classes/resources/warnings/WarningPopup";
window.onload = function () {
    var res = new Reservations();
    var addRoom = new AddRoom();
    var userRegistration = new UserRegistration();
    // Static
    var textarea = new Textarea();
    // static widgets
    var datePicker = new DatePicker('.date-picker');
    var userReauest = new UserRequest();
    var reportedProblem = new ReportedProblem();
    var tenant = new Tenant();
    var reservationCheckins = new ReservationCheckin();
    var warnings = new WarningPopup();
};
