import { constants } from "../../../constants";
var UserRegistration = /** @class */ (function () {
    function UserRegistration() {
        if (window.template === constants.template_registration) {
            UserRegistration.changeStatusDisplay();
            this.addListeners();
            this.checkIfCheckboxIsChecked();
        }
    }
    /**
     * Add listeners
     */
    UserRegistration.prototype.addListeners = function () {
        var _this = this;
        $(document).on('change', '#ceo_status', function (e) {
            _this.displayStatusDescription(e);
        });
        $(document).on('click', '#add_kpi', function (e) {
            _this.addKpiDescription(e);
        });
        $(document).on('click', '.add-co-owner', function (e) {
            _this.addCoOwner(e);
        });
        // $(document).on('click', '.test', (e: ClickEvent) => {
        //     this.addCoOwner(e);
        // });
        $(document).on('click', '#add-company-member', function (e) {
            _this.addEmployee(e);
        });
        $(document).on('change', '#registration-menu', function (e) {
            _this.changeRegistrationStep(e);
        });
        $(document).on('click', '#check-ceo-company-emplyoee', function (e) {
            _this.isCeoEmployeeEvent(e);
        });
        $(document).on('click', '.deleteEmployee', function (e) {
            _this.removeEmployee(e);
        });
        $(document).on('click', '.co-owner-delete', function (e) {
            _this.removeCoOwner(e);
        });
        $(document).on('change', '.custom-checkbox', function (e) {
            _this.toggleKPIcheckbox(e);
        });
    };
    /**
     *  Remove co owner row from list
     *
     * @param e
     */
    UserRegistration.prototype.removeCoOwner = function (e) {
        var parentElement = $(e.currentTarget).closest('.co-owner-form');
        if (parentElement.length > 0) {
            parentElement.remove();
        }
    };
    /**
     *  Remove employee form from view
     *
     * @param e
     */
    UserRegistration.prototype.removeEmployee = function (e) {
        var element = e.currentTarget;
        var containterElement = $(element).closest('.employee-form');
        var totalEmployeeForms = $('.employee-form').length;
        // If deleting last form, reset and hide it
        if (totalEmployeeForms === 1) {
            var lastForm = $('.employee-form');
            lastForm.find('input').val("");
            lastForm.addClass('d-none');
            // Since this is the last employee, ceo has to be the employee
            $('#check-ceo-company-emplyoee').prop('checked', true);
        }
        else {
            containterElement.remove();
        }
        this.updateEmployeeMetaBox(true);
    };
    UserRegistration.prototype.updateEmployeeIndexes = function () {
        var counter = 1;
        var employeeForms = $('.employee-form');
        employeeForms.each(function (index, object) {
            $(this).find('.employee_number').html(counter + "");
            counter++;
        });
    };
    /**
     *  Update total number of employees
     *
     * @param isDelete
     */
    UserRegistration.prototype.updateEmployeeMetaBox = function (isDelete) {
        var employeeCounterElement = $('input[name="total_employees"]');
        var addEmployeeButton = $("#add-company-member");
        var employeeCount = +employeeCounterElement.val();
        employeeCount += 1;
        // Update employee counter element wih count of total employee forms
        if (isDelete) {
            employeeCount -= 2;
        }
        employeeCounterElement.val(employeeCount);
        // Change link text for adding more employees
        var buttonText = addEmployeeButton.data('pluralText');
        if (employeeCount === 1) {
            buttonText = addEmployeeButton.data('pluralText');
        }
        addEmployeeButton.html(buttonText);
        this.updateEmployeeIndexes();
    };
    /**
     *  Is ceo employee checkbox event
     *
     * @param e
     */
    UserRegistration.prototype.isCeoEmployeeEvent = function (e) {
        var employeeCounterElement = $('input[name="total_employees"]');
        var employeeCount = +employeeCounterElement.val();
        var ceoEmployeeCheckbox = $(e.currentTarget).is(":checked");
        // If there are no employees and ceo is unchecked employee, then add employee
        if (employeeCount === 0) {
            if (ceoEmployeeCheckbox === false) {
                this.addEmployee(e, false);
            }
        }
    };
    UserRegistration.prototype.changeRegistrationStep = function (e) {
        var registration_step = $("#registration-menu").find(':selected').val();
        if (registration_step + "" !== "-") {
            window.location.href = registration_step + "";
        }
    };
    /**
     * Clone co owner form delete it's contents and append it
     * to the co owner form holder
     *
     * @param e
     */
    UserRegistration.prototype.addCoOwner = function (e) {
        e.preventDefault();
        var form = $(".co-owner-form")[0];
        var cloned = $(form).clone(false);
        cloned.find('input').val('');
        cloned.find('.row').append(UserRegistration.getCoOwnerDeleteColumnHTML());
        $('.co-owner-holder').append(cloned);
    };
    UserRegistration.prototype.addKpiDescription = function (e) {
        e.preventDefault();
        var form = $(".kpi_description_form")[0];
        var cloned = $(form).clone(false);
        cloned.find('input').val('');
        cloned.find('.row').append(UserRegistration.getCoOwnerDeleteColumnHTML());
        $('.kpi_description_holder').append(cloned);
    };
    /**
     *  Get HTML for delete column on co-owner data row
     *
     *  @return string
     */
    UserRegistration.getCoOwnerDeleteColumnHTML = function () {
        return "<div class=\"col-sm-1 co-owner-delete\">\n                    <div class=\"form-control invisible\"></div>\n                    <span class=\"btn-link\">\n                        <i class=\"fe-trash\"></i>\n                    </span>\n                </div>";
    };
    /**
     * Add employee
     * @param e
     * @param preventDefault
     */
    UserRegistration.prototype.addEmployee = function (e, preventDefault) {
        if (preventDefault === void 0) { preventDefault = true; }
        if (preventDefault) {
            e.preventDefault();
        }
        var form = $(".employee-form")[0];
        // Check if first form is hidden, if it is, display it and exit
        if ($(".employee-form").first().hasClass('d-none')) {
            $(".employee-form").first().removeClass('d-none');
            this.updateEmployeeMetaBox(false);
            return true;
        }
        var total_children = $(".employee-form-holder").children().length + 1;
        var cloned = $(form).clone(false);
        cloned.find('input').val("");
        //cloned.find('.added_document').remove();
        //cloned.find('.dropify-preview').hide();
        cloned.find('.employee_number').html(total_children + "");
        cloned.find('.invalid-feedback').remove();
        cloned.find('.dropify').prop('id', 'cv-file' + total_children);
        cloned.find('input:not([type=hidden])').each(function (index, element) {
            $(element).empty();
        });
        cloned.find('select').each(function (index, element) {
            $(element).prop('selectedIndex', 0);
        });
        cloned.find('.added_document').remove();
        cloned.prepend('<br>');
        $('.employee-form-holder').append(cloned);
        this.updateEmployeeMetaBox(false);
    };
    UserRegistration.prototype.displayStatusDescription = function (e) {
        e.preventDefault();
        UserRegistration.changeStatusDisplay();
    };
    /**
     * Check employee status
     */
    UserRegistration.prototype.checkValues = function () {
        var ceo_status = $("#ceo_status").length;
        if (ceo_status) {
            UserRegistration.changeStatusDisplay();
        }
    };
    /**
     * Change status display
     */
    UserRegistration.changeStatusDisplay = function () {
        var selected_status = $("#ceo_status").find(':selected').val();
        if (selected_status == "rest") {
            $(".ceo_status_description").removeClass('d-none');
        }
        else {
            $(".ceo_status_description").addClass("d-none");
        }
    };
    UserRegistration.prototype.toggleKPIcheckbox = function (e) {
        var checked = $(e.originalEvent.target);
        if (checked.is(':checked')) {
            $('.kpi-checkboxs-' + e.target.name).css('display', 'block');
        }
        else {
            $('.kpi-checkboxs-' + e.target.name).css('display', 'none');
        }
    };
    UserRegistration.prototype.checkIfCheckboxIsChecked = function () {
        var checkboxes = document.querySelectorAll('input[type=checkbox]');
        if (checkboxes) {
            checkboxes.forEach(function (checkbox) {
                var checkboxName = checkbox.getAttribute('name');
                var kpiCheckboxes = $('.kpi-checkboxs-' + checkboxName);
                if (kpiCheckboxes.length) {
                    if (checkbox.hasAttribute('checked')) {
                        $(kpiCheckboxes).css('display', 'block');
                    }
                    else {
                        $(kpiCheckboxes).css('display', 'none');
                    }
                }
            });
        }
    };
    return UserRegistration;
}());
export { UserRegistration };
