import * as moment from 'moment';
var Helper = /** @class */ (function () {
    function Helper() {
    }
    Helper.parseDefaultDate = function (date) {
        var dates = date.split(" ");
        var timeComponents = dates[1].split(":");
        var dateComponents = dates[0].split("-");
        return moment(new Date(parseInt(dateComponents[0]), (parseInt(dateComponents[1]) - 1), parseInt(dateComponents[2]), parseInt(timeComponents[0]), parseInt(timeComponents[1]), parseInt(timeComponents[2])));
    };
    Helper.convertTimeToNumericUnit = function (time) {
        var minutesUnit = time.minutes / 60;
        return time.hour + minutesUnit;
    };
    Helper.daysInMonth = function (month, year) {
        return new Date(year, month, 0).getDate();
    };
    Helper.sendAjaxRequest = function (_type, _url, _params) {
        var request = $.ajax({
            type: _type,
            url: _url,
            data: _params
        });
        request.done(function (res) {
            return request;
        });
        request.fail(function (jqXHR, textStatus) {
            console.error(jqXHR);
            return request;
        });
    };
    return Helper;
}());
export { Helper };
