import { Helper } from "../helpers/Helper";
var DatePicker = /** @class */ (function () {
    function DatePicker(selector) {
        this.selector = selector;
        this.addListeners();
        this.setupInitalPickers();
    }
    /**
     * Add listeners
     */
    DatePicker.prototype.addListeners = function () {
        var _this = this;
        $(document).on('change', this.selector + ' .year', function (e) {
            _this.getDaysForMonth(e);
        });
        $(document).on('change', this.selector + ' .month', function (e) {
            _this.getDaysForMonth(e);
        });
        $(document).on('change', this.selector + ' .day', function (e) {
            _this.setInputValue($(e.currentTarget).closest(_this.selector));
        });
    };
    /**
     * Setup date picker days for month
     *
     * @param e
     */
    DatePicker.prototype.getDaysForMonth = function (e) {
        var currentDatePicker = $(e.currentTarget).closest(this.selector);
        this.setupDatePicker(currentDatePicker);
    };
    /**
     * Setup current date picker
     *
     * @param currentDatePicker
     */
    DatePicker.prototype.setupDatePicker = function (currentDatePicker) {
        var year = currentDatePicker.find('.year').val();
        var month = currentDatePicker.find('.month').val();
        var day = currentDatePicker.find('.day').val();
        var daysInMonth = Helper.daysInMonth(parseInt(month ? month.toString() : ''), parseInt(year ? year.toString() : ''));
        currentDatePicker.find('.day option').remove();
        var dayPicker = currentDatePicker.find('.day');
        for (var i = 1; i <= daysInMonth; ++i) {
            dayPicker.append($('<option/>', {
                value: i
            }).text(i.toString()));
        }
        if (parseInt(day ? day.toString() : '') > daysInMonth) {
            dayPicker.val(daysInMonth);
        }
        else {
            dayPicker.val(day);
        }
        this.setInputValue(currentDatePicker);
    };
    /**
     * Set days on page load
     */
    DatePicker.prototype.setupInitalPickers = function () {
        var _this = this;
        $(this.selector).each(function (index, element) {
            _this.setupDatePicker($(element));
            _this.setInputValue($(element));
        });
    };
    /**
     * Setup input value base on selected dates
     *
     * @param currentDatePicker
     */
    DatePicker.prototype.setInputValue = function (currentDatePicker) {
        var year = currentDatePicker.find('.year').val();
        var month = this.normalizeDateNumber(currentDatePicker.find('.month').val());
        var day = this.normalizeDateNumber(currentDatePicker.find('.day').val());
        currentDatePicker.find('.picker-value').val(year + '-' + month + '-' + day);
    };
    DatePicker.prototype.normalizeDateNumber = function (num) {
        if (num < 10) {
            return '0' + num;
        }
        return num;
    };
    return DatePicker;
}());
export { DatePicker };
