var ReportedProblem = /** @class */ (function () {
    /**
     * ReportedProblem constructor
     */
    function ReportedProblem() {
        this.addListeners();
    }
    /**
     * Add listeners
     */
    ReportedProblem.prototype.addListeners = function () {
        var _this = this;
        $(document).on('submit', '#reported-problem-status-form', function (e) {
            _this.onReportedProblemStatusFormSubmitted(e);
        });
        jQuery("#answered-data").on('hide.bs.modal', function () {
            $("#problem-answer").removeAttr('required');
        });
    };
    /**
     * Triggered when reported problem status form is submitted
     *
     * @param e SubmitEvent
     */
    ReportedProblem.prototype.onReportedProblemStatusFormSubmitted = function (e) {
        if ($("#status-problem").val() === ReportedProblem.STATUS_ANSWERED) {
            if ($('#problem-answer').val().toString().length === 0) {
                e.preventDefault();
                e.stopImmediatePropagation();
                e.stopPropagation();
                $("#problem-answer").attr('required', 'required');
                jQuery("#answered-data").modal();
            }
        }
    };
    ReportedProblem.STATUS_ANSWERED = 'answered';
    return ReportedProblem;
}());
export { ReportedProblem };
