import { constants } from "../../constants";
import { ReservationFactory } from "../factories/ReservationFactory";
var ReservationService = /** @class */ (function () {
    function ReservationService() {
    }
    /**
     * Get reservations from server in between start date and
     * end date for a specific room
     *
     * @param startDate
     * @param endDate
     * @param roomId
     */
    ReservationService.prototype.getReservations = function (startDate, endDate, roomId) {
        return new Promise(function (resolve, reject) {
            var uri = constants.get_api_endpoint() + constants.endpoint_load_reservations;
            uri = uri.replace('{start_date}', encodeURIComponent(startDate.format('YYYY-MM-DD hh:mm:ss')));
            uri = uri.replace('{end_date}', encodeURIComponent(endDate.format('YYYY-MM-DD hh:mm:ss')));
            uri = uri.replace('{room_id}', roomId.toString());
            $.get(uri)
                .done(function (response) {
                if (response.status === constants.success) {
                    var reservationModels = response.data.map(function (item) {
                        return ReservationFactory.createReservation(item);
                    });
                    resolve(reservationModels);
                }
                else {
                    reject("Error loading data");
                }
            })
                .fail(function () {
                reject("Error loading data");
            });
        });
    };
    ReservationService.prototype.getRoomTakenTimes = function (date, startTime, endTime, roomId, excludeParentId) {
        if (excludeParentId === void 0) { excludeParentId = null; }
        var data = {
            room_id: roomId,
            dates: {}
        };
        data.dates[date] = [
            startTime,
            endTime
        ];
        if (excludeParentId) {
            data.exclude_parent_id = excludeParentId;
        }
        return new Promise(function (resolve, reject) {
            var uri = constants.get_root_api_endpoint() + constants.endpoint_reservation_times;
            $.get(uri, data)
                .done(function (response) {
                if (response.status === constants.success) {
                    if (response.data[date]) {
                        resolve(response.data[date]);
                    }
                    else {
                        resolve([]);
                    }
                }
                else {
                    reject("Error loading data");
                }
            })
                .fail(function () {
                reject("Error loading data");
            });
        });
    };
    return ReservationService;
}());
export { ReservationService };
