var UserRequest = /** @class */ (function () {
    /**
     * UserRequest constructor
     */
    function UserRequest() {
        this.addListeners();
    }
    /**
     * Add listeners
     */
    UserRequest.prototype.addListeners = function () {
        var _this = this;
        $(document).on('submit', '#user-request-status-form', function (e) {
            _this.onUserRequestStatusFormSubmitted(e);
        });
        jQuery("#rejection-reason").on('hide.bs.modal', function () {
            $("#reason").removeAttr('required');
        });
        $(document).on('click', '.print-request-data', function (e) {
            _this.printRequestData(e);
        });
    };
    /**
     * Triggered when user request status form is submitted
     *
     * @param e SubmitEvent
     */
    UserRequest.prototype.onUserRequestStatusFormSubmitted = function (e) {
        if ($("#status-lab").val() === UserRequest.STATUS_REJECTED) {
            if ($('#reason').val().toString().length === 0) {
                e.preventDefault();
                $("#reason").attr('required', 'required');
                jQuery("#rejection-reason").modal();
            }
        }
    };
    /**
     * Print request data
     *
     * @param e
     */
    UserRequest.prototype.printRequestData = function (e) {
        var link = $(e.target).data('link');
        if (link) {
            window.open(link).print();
        }
        else {
            window.print();
        }
    };
    UserRequest.STATUS_REJECTED = 'rejected';
    return UserRequest;
}());
export { UserRequest };
