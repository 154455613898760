export var translations = {
    dateFormat: "D. M. YYYY",
    mon: "Ponedjeljak",
    tue: "Utorak",
    wed: "Srijeda",
    thu: "Četvrtak",
    fri: "Petak",
    sat: "Subota",
    sun: "Nedjelja",
    taken: "Zauzeto",
    suvlasnik: "Suvlasnik",
    branch: "Podružnica #",
    remove: "Ukloni"
};
